//
// user.scss
// Use this to write your custom SCSS
//

.text-gradient-primary {
background: linear-gradient(to right, $primary, #bf295f, #f1387b);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

//
// user-variables.scss
// Use this to override Bootstrap and Finder variables
//

// Example of a variable override to change Finder background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #12263F;



// Grayscale
$white:                       #fff !default;
$gray-100:                    #f3f6ff !default;
$gray-200:                    #eff2fc !default;
$gray-300:                    #e2e5f1 !default;
$gray-400:                    #d4d7e5 !default;
$gray-500:                    #b4b7c9 !default;
$gray-600:                    #9397ad !default;
$gray-700:                    #565973 !default;
$gray-800:                    #33354d !default;
$gray-900:                    #0b0f19 !default;
$black:                       #000 !default;

// Theme colors
$primary:                     #74344e !default;
$secondary:                   $gray-200 !default;
$info:		                    #4c82f7 !default;
$success:                     #22c55e !default;
$warning:                     #ffba08 !default;
$danger:                      #fe2c42 !default;
$light:                       $white !default;
$dark:                        $gray-900 !default;

// Font

$path-to-fonts:                            'https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap' !default;

$font-family-sans-serif:                    'Roboto', sans-serif !default;

$font-size-base:                            1rem !default;
$font-size-xl:                              $font-size-base * 1.25 !default;
$font-size-lg:                              $font-size-base * 1.125 !default;
$font-size-sm:                              $font-size-base * .875 !default;
$font-size-xs:                              $font-size-base * .75 !default;

$font-weight-normal:                        100 !default;
$font-weight-medium:                        400 !default;
$font-weight-semibold:                      700 !default;
$font-weight-bold:                          800 !default;

$line-height-base:                          1.6 !default;
$line-height-sm:                            1.4 !default;
$line-height-lg:                            1.8 !default;

$h1-font-size:                              $font-size-base * 3.5 !default;
$h2-font-size:                              $font-size-base * 2.2 !default;
$h3-font-size:                              $font-size-base * 1.15 !default;
$h4-font-size:                              $font-size-base * 1.2 !default;
$h5-font-size:                              $font-size-base * 1.15 !default;
$h6-font-size:                              $font-size-base !default;

// Modifying default Bootstrap's $font-sizes map
$font-sizes: (
  1:                                        $h1-font-size,
  2:                                        $h2-font-size,
  3:                                        $h3-font-size,
  4:                                        $h4-font-size,
  5:                                        $h5-font-size,
  6:                                        $h6-font-size,
  'xl':                                     $font-size-xl,
  'lg':                                     $font-size-lg,
  'base':                                   $font-size-base,
  'sm':                                     $font-size-sm,
  'xs':                                     $font-size-xs
) !default;

$lead-font-size:                            $font-size-base * 1.5 !default;
$lead-font-weight:                          $font-weight-normal !default;


$headings-font-weight:                      $font-weight-normal !default;
$headings-line-height:                      1.3 !default;
$headings-color:                            $gray-900 !default;
$headings-dark-mode-color:                  $white !default;

// Modifying default Bootstrap's $display-font-sizes map
$display-font-sizes: (
  1: $font-size-base * 5,
  2: $font-size-base * 4.5,
  3: $font-size-base * 4,
  4: $font-size-base * 3.5,
  5: $font-size-base * 3,
  6: $font-size-base * 2.625
) !default;

$display-font-weight:                       $font-weight-normal !default;
